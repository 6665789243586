import useData from "../../hooks/useData";
import React, {useState} from 'react';
import {SpeedDial} from 'primereact/speeddial';
import {baseInfo} from "../../utils/helper";
import {Skeleton} from 'primereact/skeleton';
import defaultImg from "../../assets/default.jpg"
import useFetch from "../../hooks/useFetch";

export default function Navigation() {
    const {selectId,setSelectId} = useData();
    const [active,setActive] = useState(defaultImg)
    const {data,loading,error} = useFetch('users')
    const items = data.map((d)=>{
        return {
            label:d?.name,
            icon:<img alt={'hello'} src={`${baseInfo.url}${d?.image}`}/>,
            command: ()=>{
                setSelectId(d?.uuid);
                setActive(`${baseInfo.url}${d?.image}`)
            }
        }
    })
    const finalData = [
        {
            label:"All",
            icon:<img alt={'hello'} className={'object-cover'} src={require("../../assets/group.jpg")}/>,
            command: ()=>{
                setSelectId(null)
                setActive(defaultImg)
            }
        },
        ...items
    ]
    return (
        <>
            {
                !error ? <div>
                    {
                        !loading
                            ? <SpeedDial model={finalData} direction="up"
                                         className="speeddial-bottom-right right-5 z-10 bottom-5" buttonClassName="p-button-danger wob" style={{"--b": `${selectId !== null ? 'linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5))' : ''},url(${active})`}} />
                            : <div className={'fixed bottom-[20px] right-5' }>
                                <Skeleton shape="circle" size="3rem"></Skeleton>
                            </div>
                    }
                </div> : "something went wrong"
            }
        </>
    )
}
