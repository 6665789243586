import React, {useEffect, useRef, useState} from "react";
import FullImg from "../../components/FullImg/FullImg";
import {useNavigate} from "react-router-dom";
import useData from "../../hooks/useData";
import Odo from "../../components/Odo/Odo";
import {Dialog} from 'primereact/dialog';
import {Toast} from 'primereact/toast';
import useFetch from "../../hooks/useFetch";
import Err from "../../components/Err/Err";

export const Home = () => {
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const audioRef = useRef(null)
    const {update, setUpdate} = useData();
    const [staticLoading, setStaticLoading] = useState(true);
    const navigate = useNavigate();
    const [id, setId] = useState(null);
    const {data, loading, error} = useFetch('users')


    useEffect(() => {
        setTimeout(() => {
            setStaticLoading(false)
        }, 1500)
    }, [staticLoading]);


    const forward = () => {
        const existCheck = data.some(d => d.uuid === id);
        if (id === "SL007" || existCheck) {
            localStorage.setItem("loaded", true);
            localStorage.setItem("id", id);
            navigate("/home");
            setUpdate(!update)
        } else {
            toast.current.show({severity: 'error', summary: 'Error', detail: 'Incorrect ID, Please try again.'});
        }
    }
    if (staticLoading) {
        return <div className={'h-screen w-full flex items-center justify-center text-white'}>
            <img alt={'nature'} className={'fixed w-44 top-0 right-0 z-10'} src={require("../../assets/leaf.png")}/>
            <img alt={'nature'} className={'fixed w-full bottom-0 left-0 z-10'} src={require("../../assets/hill.png")}/>
            <div className="circle-container">
                <svg fill="none" className="circle-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle className="circle" cx="50" cy="50" r="45"/>
                </svg>
            </div>
        </div>
    }
    if (loading) {
        return <div className={'h-screen w-full flex items-center justify-center text-white'}>
            <img alt={'nature'} className={'fixed w-44 top-0 right-0 z-10'} src={require("../../assets/leaf.png")}/>
            <img alt={'nature'} className={'fixed w-full bottom-0 left-0 z-10'} src={require("../../assets/hill.png")}/>
            <div className="circle-container">
                <svg fill="none" className="circle-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle className="circle" cx="50" cy="50" r="45"/>
                </svg>
            </div>
        </div>
    }
    const footerContent = (
        <>
            <button onClick={() => setVisible(false)}
                    className={'py-3 px-8 text-center rounded text-sm text-white bg-red-500'}>Cancel
            </button>
            <button onClick={forward}
                    className={'py-3 px-8 text-center rounded text-sm text-white bg-green-500'}>Proceed
            </button>
        </>
    );
    if (error){
        return <Err error={'Failed to load data'}/>
    }
    return (
        <div className={'h-screen flex items-center justify-center'}>
            <img alt={'nature'} className={'fixed w-44 top-0 right-0 z-10'} src={require("../../assets/leaf.png")}/>
            <img alt={'nature'} className={'fixed w-full bottom-0 left-0 z-10'} src={require("../../assets/hill.png")}/>
            <Toast ref={toast}/>
            <Dialog header="Type Your ID" visible={visible} position={"bottom"} style={{
                width: window.innerWidth > 700 ? '30vw' : '94%',
                backgroundImage: `url('https://i.pinimg.com/originals/da/0e/fd/da0efd71f81d4e78432965d39ae0ffb9.jpg')`,
                color: 'white',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                <input type={'text'} onChange={(e) => setId(e.target.value)} placeholder={'Type your id'}
                       className={'p-4 w-full focus:outline-none rounded text-sm bg-white'}/>
            </Dialog>
            <div className={'relative z-50'}>
                <div className={'transition-all text-white duration-400 p-4'}>
                    <h1 className={'text-5xl font-bold'}><span>Selopia</span> <br/> <span>Tour Care</span></h1>
                    <p className={'text-base'}>“Remember that happiness is a way of travel – not a destination.” – Roy
                        M. Goodman</p>
                </div>
                <Odo reference={audioRef}/>
                <div className={'grid grid-cols-1 gap-4 p-4'}>
                    <FullImg song={"/bandarban.mp3"} reff={audioRef} height={'h-44'} img={"bandarban.jpg"}
                             name={"Lama"}/>
                    <div className={'grid grid-cols-2 gap-4'}>
                        <FullImg song={"/hiking.mp3"} reff={audioRef} height={'h-28'} img={"hiking.jpg"}
                                 name={"Hiking"}/>
                        <FullImg song={"/bbq.mp3"} reff={audioRef} height={'h-28'} img={"bbq.jpg"} name={"BBQ"}/>
                    </div>
                </div>
                <div className={'p-4'}>
                    <button onClick={() => setVisible(true)}
                            className={'p-3 block text-center rounded-xl w-full text-lg text-white'}
                            style={{background: '#265CFF'}}>Get Started
                    </button>
                </div>
            </div>
        </div>
    )
};
