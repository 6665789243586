import React from "react";

export default function FullImg ({img,name,height,reff,song}) {
    const go = ()=>{
        reff.current.src = song;
        reff.current.load();
    }
    return (
        <div onMouseOver={go} className={'relative text-white shadow rounded-xl group overflow-hidden'}>
            <div className={'absolute h-full w-full flex items-end p-4 z-10'}>
                <div>
                    <h1 className={'text-2xl font-bold'}>{name}</h1>
                </div>
            </div>
            <img
                src={require(`../../assets/${img}`)}
                className={`${height ? height : ''} w-full object-cover scale-100 group-hover:scale-150 transition-all duration-1000 overflow-hidden rounded-xl`} alt={name}/>
        </div>
    )
}
