import React, {useRef} from "react";
import GoogleMap from "google-maps-react-markers";
import {baseInfo} from "../../utils/helper";
import Marker from "./Marker"

export default function MapLocation({data}){


    // MAP REFERENCE & FUNCTION FOR APPENDING GOOGLE MAP
    const mapRef = useRef(null);
    const onGoogleApiLoaded = ({ map }) => {
        mapRef.current = map;
    };


    return <GoogleMap
        apiKey={baseInfo.mapkey}
        defaultCenter={{ lat: Number(data[0]?.lat), lng: Number(data[0]?.lon) }}
        defaultZoom={20}
        mapMinHeight='100vh'
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{
                disableDefaultUI: true,
                backgroundColor: "#131a20",
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "all",
                        "stylers": [
                            {
                                "invert_lightness": true
                            },
                            {
                                "saturation": 10
                            },
                            {
                                "lightness": 30
                            },
                            {
                                "gamma": 0.5
                            },
                            {
                                "hue": "#435158"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "stylers": [
                            {
                                "visibility":"off"
                            }
                        ]
                    }
                ]
            }}>
        {
            data?.map((loc,i)=>{
                return <Marker
                    key={i}
                    lat={Number(loc?.lat)}
                    id={1}
                    img={loc?.image}
                    name={loc?.name}
                    time={loc?.createdAt}
                    lng={Number(loc?.lon)}
                    className={'rounded-full'}
                />
            })
        }
    </GoogleMap>
}