import React, {useEffect, useMemo, useState} from 'react'
import {baseInfo} from "../../utils/helper";
import useData from "../../hooks/useData";
import MapLocation from "../../components/MapLocation/MapLocation";
import Options from "../../components/Options/Options"
import Err from "../../components/Err/Err";
import {Link} from "react-router-dom";

export default function Location() {
    // LOCAL AND GLOBAL STATE
    const {selectId, localId} = useData();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // DATA FETCHING WITH DYNAMIC ROUTE & CONDITION THROUGH USE_MEMO HOOK TO OPTIMIZE RE-RENDERING
    const fetchData = useMemo(() => async () => {
        setLoading(true);
        let url;
        if(localId==="SL007" && !selectId ){
            // global
            url = baseInfo.url;
        } else if(localId && localId!=="SL007"){
            // individual without selection
            url = `${baseInfo.url}/location?uuid=${localId}`;
        } else if(localId && selectId) {
            // individual with selection
            url = `${baseInfo.url}/location?uuid=${selectId}`;
        } else {
            return
        }
        try {
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Static-Key": `${baseInfo.staticToken}`
                }
            });
            const resData = await res.json();
            if (res.status !== 204) {
                setData(resData.data);
            }
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [selectId,localId]);

    // USING USE_EFFECT HOOK TO RENDER EVERY ID CHANGE AND INITIAL INTERFACE
    useEffect(() => {
        fetchData().then();
    }, [fetchData]);

    return <div className={'overflow-hidden bg-black'}>
        {
            !error ? <div>
                {
                    !loading ? <div>
                        <MapLocation data={data}/>
                    </div> : <div className={'h-screen w-full flex items-center justify-center text-white'}>
                        <img alt={'nature'} className={'fixed w-44 top-0 right-0 z-10'} src={require("../../assets/leaf.png")}/>
                        <img alt={'nature'} className={'fixed w-full bottom-0 left-0 z-10'} src={require("../../assets/hill.png")}/>
                        <div className="circle-container">
                            <svg fill="none" className="circle-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle className="circle" cx="50" cy="50" r="45"/>
                            </svg>
                        </div>
                    </div>
                }
            </div> : <Err error={error}/>
        }
        {(localId === "SL007") && <Options/>}
        {(localId === "SL007") && <Link to={'/distance'} className={'fixed bottom-[20px] right-20 bg-indigo-500 z-20 h-12 rounded-full overflow-hidden w-12'}>
            <img alt={'footpath'} className={'w-full h-full'} src={"footpath.png"}/>
        </Link>}
    </div>
}
