import {useNavigate} from "react-router-dom"
import useData from "../../hooks/useData";

export default function Error() {
    let navigate = useNavigate();
    const {setLoaded, update, setUpdate} = useData();
    const go = () => {
        localStorage.setItem("loaded", false);
        localStorage.setItem("id", null);
        setLoaded(false);
        navigate("/");
        setUpdate(!update)
    }
    return <div className={'h-screen flex items-center justify-center text-white text-center'}>
        <div>
            <img className={'w-40 h-40 mx-auto object-cover'} alt={'error'}
                 src={'https://cdn-icons-png.flaticon.com/512/2496/2496231.png'}/>

            <div className={'p-4'}>
                <h1 className={'text-5xl font-semibold'}>Page Not Found</h1>
                <p className={'text-gray-100 mt-2 text-xs'}>Searched page may not exists here</p>

                <button onClick={() => navigate(-1)}
                        className={'p-3 block mt-8 rounded-xl w-full bg-green-500 text-sm'}>Back to Previous
                </button>
                <button onClick={go} className={'p-3 block mt-2 rounded-xl w-full bg-blue-600 text-sm'}>Back to Home
                </button>
            </div>
        </div>
    </div>
}