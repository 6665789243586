import React, { createContext } from "react";
import useGlobalContext from "../hooks/useGlobalContext";
export const AllContext = createContext();
const ContextProvider = ({ children }) => {
  const store = useGlobalContext()
  return (
    <AllContext.Provider value={store}>{children}</AllContext.Provider>
  );
};

export default ContextProvider;
