import {useEffect, useState} from "react";
import {baseInfo} from "../utils/helper";
const useFetch=(endpoint)=>{
    const [data,setData] = useState([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true)
                const res = await fetch(`${baseInfo.url}/${endpoint}`, {
                    method: "GET",
                    headers: {
                        "Static-Key": `${baseInfo.staticToken}`
                    }
                });

                if (res.status !== 204) {
                    const resData = await res.json();
                    setData(resData.data)
                }
            } catch (e) {
                setError(e.message)
            } finally {
                setLoading(false)
            }
        }
        getData().then();
    }, [endpoint]);
    return {loading,error,data}
}
export default useFetch