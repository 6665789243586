import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import ContextProvider from "./Context/ContextProvider";

const come_down = ReactDOM.createRoot(document.getElementById("__next-14.0.2"));
come_down.render(
  // <React.StrictMode>
    <ContextProvider>
        <App />
    </ContextProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
