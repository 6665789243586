import {createBrowserRouter} from "react-router-dom";
import {Home} from "../Pages/Home/Home";
import Location from "../Pages/Location/Location";
import Error from "../Pages/Error/Error";
import AuthWrapper from "../AuthWrapper/AuthWrapper";
import Distance from "../Pages/Distance/Distance";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthWrapper><Home/></AuthWrapper>
  },
  {
    path: "/home",
    element: <Location/>
  },
  {
    path: "/distance",
    element: <Distance/>
  },
  {
    path: "*",
    element: <Error/>
  }
]);
