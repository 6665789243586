import {Navigate,useLocation} from "react-router-dom";
import useData from "../hooks/useData";

export default function AuthWrapper ({children}){
    const {loaded} = useData();
    const location = useLocation();
    if (loaded==='true'){
       return <Navigate to="/home" state={{ from: location }} />
    }
    return children
}