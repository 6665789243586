import React from "react";
import useData from "../../hooks/useData";
import {useNavigate} from "react-router-dom";

export default function Err ({error}){
    const {update,setUpdate,setLoaded} = useData();
    const navigate = useNavigate()
    const go = ()=>{
        localStorage.setItem("loaded",false);
        localStorage.setItem("id",null);
        setLoaded(false);
        navigate("/");
        setUpdate(!update)
    }
    return <div className={'h-screen w-full text-white'}>
        <div className={'text-center p-8 h-full flex items-center justify-center'}>
            <div>
                <img className={'h-44 w-44 mx-auto scale-50 hover:scale-100 transition-all duration-1000'} alt={'failded to fetch'}
                     src={'https://cdn-icons-png.flaticon.com/512/3394/3394167.png'}/>
                <h1 className={'text-3xl font-semibold'}>Failed to load data</h1>
                <p className={'text-gray- mt-2 text-xs'}>{error.message}</p>
                <button onClick={() => window.location.reload()} className={'text-gray-100 mt-12 bg-green-600 rounded-xl p-3 block w-full text-center text-sm'}>Try again</button>
                <button onClick={go} className={'text-gray-100 mt-2 bg-blue-600 rounded-xl p-3 block w-full text-center text-sm'}>Back to home</button>
            </div>
        </div>
    </div>
}