import React from 'react';
import {baseInfo, formatTimeAgo} from "../../utils/helper";
import useData from "../../hooks/useData";

export default function Marker({className, lat, lng, name, time, id, img, ...props}) {
    const {selectId,localId} = useData()
    return (
        <div className="group">



            {( localId!=="SL007" || (localId==="SL007" && selectId)  )  && <div style={{background: '#456574'}}
                                       className={'text-gray-200 p-1 whitespace-nowrap w-20 text-center mb-3 relative'}>
                {formatTimeAgo(time)}
                <div style={{background: '#456574'}}
                     className={'w-3 h-3 rotate-45 left-2 absolute -bottom-[6px]'}></div>
            </div>}
            <img
                // sl077 && !SID
                //
                className={className}
                src={(localId==="SL007" && selectId === null) ? `${baseInfo.url}${img}` : "https://cdn-icons-png.flaticon.com/512/684/684908.png"}
                lat={lat}
                lng={lng}
                style={{
                    cursor: "pointer",
                    fontSize: 10,
                    height: "25px",
                    width: "25px",
                }}
                alt="marker"
                {...props}
            />
        </div>
    );
}
