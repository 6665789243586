export const baseInfo = {
    url : 'https://selocare.selopian.us',
    staticToken:'igy87BNOHIO*Yknfut6fikbiuTY*&lnlihyy98tFR^*GkbIF67r8gujbUYRrew',
    mapkey:'AIzaSyA9jP21zQkEokYGtvYIvJjxJ3vdOz_mOqs'
}
export const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    if (timestamp.length<=0) {
        return <span className="text-red-500">No data</span>
    }
    const timeDifferenceInSeconds = Math.floor((now - date) / 1000);

    if (timeDifferenceInSeconds < 60) {
        return "Just now";
    }

    const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);

    if (timeDifferenceInMinutes < 60) {
        return `${timeDifferenceInMinutes} minute${
            timeDifferenceInMinutes > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);

    if (timeDifferenceInHours < 24) {
        return `${timeDifferenceInHours} hour${
            timeDifferenceInHours > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);

    if (timeDifferenceInDays < 30) {
        return `${timeDifferenceInDays} day${
            timeDifferenceInDays > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInMonths = Math.floor(timeDifferenceInDays / 30);

    if (timeDifferenceInMonths < 12) {
        return `${timeDifferenceInMonths} month${
            timeDifferenceInMonths > 1 ? "s" : ""
        } ago`;
    }
    const timeDifferenceInYears = Math.floor(timeDifferenceInMonths / 12);
    return `${timeDifferenceInYears} year${
        timeDifferenceInYears > 1 ? "s" : ""
    } ago`;

}