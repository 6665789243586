import React, {useEffect, useState} from "react";
import {baseInfo, formatTimeAgo} from "../../utils/helper";
import Err from "../../components/Err/Err";
import { TfiAngleLeft } from "react-icons/tfi";
import {useNavigate} from "react-router-dom";


const Distance = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        const getData = async ()=>{
            setLoading(true)
            try {
                const res = await fetch(`${baseInfo.url}/path`,{
                    method: "GET",
                    headers: {
                        "Static-Key": `${baseInfo.staticToken}`
                    }
                })
                const resData = await res.json();
                if (res.status !== 204) {
                    setData(resData.data);
                }
            }catch (e) {
                setError(e.message)
            }finally {
                setLoading(false)
            }
        }
        getData().then();
    }, []);
    return (
        <div className={'h-screen overflow-auto text-white p-4'}>
            <h1 className={'text-2xl flex items-center'}><TfiAngleLeft onClick={()=>navigate('/home')} size={18} className={'mr-3'}/>Live Pathway</h1>
            {
                !error ? <>
                    {
                        !loading ? <table className={'text-white w-full mt-4'}>
                            <thead>
                            <tr className={'border-b'}>
                                <th className={'pb-2 text-sm text-left'}>Person</th>
                                <th className={'pb-2 text-sm text-center'}>Distance</th>
                                <th className={'pb-2 text-sm text-end'}>Last Updated</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data?.map((d,i)=>{
                                    return <tr>
                                        <td>
                                            <div className="flex items-center w-44 gap-x-1 py-2">
                                                <img className="h-8 w-8 flex-none rounded-full bg-gray-50" src={`${baseInfo.url}${d?.image}`} alt=""/>
                                                <div className="min-w-0 flex-auto">
                                                    <p className="whitespace-nowrap text-sm leading-6 text-gray-200">{d?.name}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={'text-sm text-gray-300'}>{d?.distance}</div>
                                        </td>
                                        <td>
                                            <div className={'text-end text-sm text-gray-400'}>{formatTimeAgo(d.createdAt)}</div>
                                        </td>
                                    </tr>
                                })
                            }

                            </tbody>
                        </table> : <div className={'h-screen w-full flex items-center justify-center text-white'}>
                            <img alt={'nature'} className={'fixed w-44 top-0 right-0 z-10'} src={require("../../assets/leaf.png")}/>
                            <img alt={'nature'} className={'fixed w-full bottom-0 left-0 z-10'} src={require("../../assets/hill.png")}/>
                            <div className="circle-container">
                                <svg fill="none" className="circle-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="circle" cx="50" cy="50" r="45"/>
                                </svg>
                            </div>
                        </div>
                    }
                </> : <Err error={'Failed to load data'}/>
            }

        </div>
    )
}
export default Distance