import {useEffect, useState} from "react";

const useGlobalContext = () => {
    const [localId, setlocalId] = useState(null);
    const [loaded, setLoaded] = useState(null);
    const [update, setUpdate] = useState(false);
    const [selectId, setSelectId] = useState(null);
    useEffect(() => {
        const isLoaded = localStorage.getItem("loaded");
        const id = localStorage.getItem("id");
        setLoaded(isLoaded)
        setlocalId(id)
    }, [update]);



    return {selectId, setSelectId, loaded,setLoaded,update,setUpdate,localId};
};

export default useGlobalContext;
